





























import { api } from "@/api/api";
import { ApiGetGrantRateDto, ApiUpsertGrantRateDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateCourseHourInput } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import { getYear } from "date-fns";
export default defineComponent({
  name: "TrainingGrantsModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    existingItemModal: {
      type: Object as PropType<ApiGetGrantRateDto>,
    },
  },
  emits: ["close"],
  setup(props, { refs, emit }) {
    const store = useStore<StoreState>();
    const grant = ref<ApiUpsertGrantRateDto>(getInitialGrant());
    const currentYear = getYear(Date.now());
    const headline = computed(() => {
      if (props.modalType === ModalType.Add) {
        return currentYear.toString();
      }
      return props.existingItemModal?.year.toString();
    });

    if (props.existingItemModal && (props.modalType === ModalType.Edit || props.modalType === ModalType.Display)) {
      grant.value = deepCloneObject(props.existingItemModal);
    }

    const createGrant = async () => {
      await api.economy.createGrantRate(currentYear, grant.value);
      openNotification(store, NotificationItemType.Success, `Opplæringstilskudd opprettet for ${currentYear}`);
      emit("close");
    };
    const updateGrant = async () => {
      if (!props.existingItemModal?.year) {
        return;
      }
      await api.economy.updateGrantRate(props.existingItemModal.year, grant.value);
      openNotification(
        store,
        NotificationItemType.Success,
        `Opplæringstilskudd oppdatert for ${props.existingItemModal.year}`
      );
      emit("close");
    };

    const handleSubmit = () => {
      const isValid = getValidatableRef(refs.connectForm)?.validate();
      if (!isValid) {
        return;
      }
      if (props.modalType === ModalType.Add) {
        createGrant();
      } else if (props.modalType === ModalType.Edit) {
        updateGrant();
      } else {
        openNotification(store, NotificationItemType.Error, "Det skjedde en feil");
      }
    };

    return {
      grant,
      headline,
      handleSubmit,
      validateCourseHourInput,
      readonly: computed(() => props.modalType === ModalType.Display),
    };
  },
});

const getInitialGrant = (): ApiUpsertGrantRateDto => ({
  teachingGrantAmount: 0,
  extraProfessionalCourseAmount: 0,
});
